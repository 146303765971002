import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductType } from 'src/api/productType'
import { IGetRecipesByProductResponse } from 'src/api/products'
import { ProductCategory, Product, ProposalTemplate, ProductImage } from 'src/common/common.interface'
import { ProductImageType } from 'src/common/enum'

export interface ProductState {
  productCategories: ProductCategory[]
  products: Product[]
  countProduct: number
  product?: Product
  recipeTemplate?: IGetRecipesByProductResponse
  loadingGetListProducts: boolean
  loadingMore: boolean
  productTypes?: ProductType[]
  productLoading: number
  recipeLoading: boolean
}

const initialState: ProductState = {
  productCategories: [],
  products: [],
  countProduct: -1,
  product: undefined,
  recipeTemplate: undefined,
  loadingGetListProducts: false,
  loadingMore: false,
  productTypes: undefined,
  productLoading: 0,
  recipeLoading: false
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    startProductAPI(state) {
      state.productLoading += 1
    },
    endProductAPI(state) {
      state.productLoading -= 1
    },
    setProductCategoriesData(state, action: PayloadAction<ProductCategory[]>) {
      state.productCategories = action.payload
    },
    setProductTypes(state, action: PayloadAction<ProductType[]>) {
      state.productTypes = action.payload
    },
    setLoadingGetListProducts(state, action: PayloadAction<boolean>) {
      state.loadingGetListProducts = action.payload
    },
    setLoadingMore(state, action: PayloadAction<boolean>) {
      state.loadingMore = action.payload
    },
    setProductsData(state, action: PayloadAction<Product[]>) {
      state.products = action.payload
    },
    addMoreProductsData(state, action: PayloadAction<Product[]>) {
      state.products = state.products.concat(action.payload)
    },
    setCountProduct(state, action: PayloadAction<number>) {
      state.countProduct = action.payload
    },
    setProductData(state, action: PayloadAction<Product>) {
      if (!action.payload.name) {
        state.product = undefined
      } else {
        state.product = action.payload
      }
    },
    add1ProductData(state, action: PayloadAction<Product>) {
      state.products.push({ ...action.payload })
    },
    update1ProductData(state, action: PayloadAction<Product>) {
      const products = [...state.products]
      const index = products.findIndex((p) => p.id === action.payload.id)
      if (index > -1) {
        products[index] = action.payload
        state.products = [...products]
      }
    },
    setRecipeTemplateData(state, action: PayloadAction<IGetRecipesByProductResponse>) {
      if (!action.payload.recipeTemplate && !action.payload.recipe && !action.payload.recipeCount) {
        state.recipeTemplate = undefined
      } else {
        state.recipeTemplate = action.payload
      }
    },
    addTemplates(state, action: PayloadAction<ProposalTemplate[]>) {
      if (state.product?.templates) {
        action.payload.forEach((template) => {
          if (!state.product?.templates?.find((t) => t.id === template.id)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.product.templates.push(template)
          }
        })
      } else if (state.product) state.product.templates = action.payload
    },
    updateAProductImage(state, action: PayloadAction<ProductImage>) {
      if (state.product) {
        const imageIndex = state.product.images.findIndex((i) => i.id === action.payload.id)
        if (action.payload.type === ProductImageType.DEFAULT) {
          const oldImg = state.product.images.find((i) => i.type === ProductImageType.DEFAULT)
          if (oldImg) {
            oldImg.type = oldImg.eventId ? ProductImageType.RESULT : ProductImageType.DEPRECATED
          }
        }
        if (imageIndex > -1) {
          state.product.images[imageIndex] = action.payload
        }
      }
    },
    createAProductImage(state, action: PayloadAction<ProductImage>) {
      if (state.product) {
        if (action.payload.type === ProductImageType.DEFAULT) {
          const oldImg = state.product.images.find((i) => i.type === ProductImageType.DEFAULT)
          if (oldImg) {
            oldImg.type = oldImg.eventId ? ProductImageType.RESULT : ProductImageType.DEPRECATED
          }
        }
        state.product.images.push(action.payload)
      }
    },
    updateNewProductFromProductImage(state, action: PayloadAction<{ imageId: number; newProductId: number }>) {
      if (state.product) {
        const image = state.product.images.find((i) => i.id === action.payload.imageId)
        if (image) {
          image.newProductId = action.payload.newProductId
        }
      }
    },
    setRecipeLoading(state, action: PayloadAction<boolean>) {
      state.recipeLoading = action.payload
    }
  }
})

export const productAction = productSlice.actions

export default productSlice.reducer
